
.problem-answer-container {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  .problem-answer-nav {
    .problem-answer-stuname {
      font-weight: bold;
      font-size: 16px;
    }
    .problem-answer-store {
      margin-left: 50px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .el-table {
    margin-top: 34px;
    flex:1;
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #fff;
      background: rgba(17, 34, 216, 1);
    }
    ::v-deep .el-radio__input.is-checked + .el-radio__label {
      color: rgba(17, 34, 216, 1);
    }

    ::v-deep .el-button--primary {
      border-color: rgba(17, 34, 216, 1);
      background: rgba(17, 34, 216, 1);
    }
  }
  ::v-deep .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link {
      font-weight: 400;
      color: #606266;
    }
  }
  .pages-center {
    margin-top: 15px;
    text-align: center;
  }
}
